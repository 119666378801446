<template>
  <form class="form-horizontal">
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.full_name}"
        >
          <label>
            Full name / Company
          </label>
          <input
            ref="fullName"
            v-model="billingDetails.full_name"
            type="text"
            class="form-control"
            placeholder="Full name"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.full_name"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.email}"
        >
          <label>Email address</label>
          <input
            v-model="billingDetails.email"
            type="email"
            class="form-control"
            placeholder="Email address"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.email"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.phone}"
        >
          <label>Phone number</label>
          <input
            v-model="billingDetails.phone"
            type="text"
            class="form-control"
            placeholder="Phone number"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.phone"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.address_line_1}"
        >
          <label>Address line 1</label>
          <input
            v-model="billingDetails.address_line_1"
            type="text"
            class="form-control"
            placeholder="Address"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.address_line_1"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.address_line_2}"
        >
          <label>Address line 2</label>
          <input
            v-model="billingDetails.address_line_2"
            type="text"
            class="form-control"
            placeholder="Address"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.address_line_2"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.town}"
        >
          <label>Town/City</label>
          <input
            v-model="billingDetails.town"
            type="text"
            class="form-control"
            placeholder="Town/City"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.town"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.county_state}"
        >
          <label>County/State</label>
          <input
            v-model="billingDetails.county_state"
            type="text"
            class="form-control"
            placeholder="Country/State"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.county_state"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.postcode}"
        >
          <label>Postcode</label>
          <input
            v-model="billingDetails.postcode"
            type="text"
            class="form-control"
            placeholder="Postcode"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.postcode"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.country_id}"
        >
          <label>
            Country
          </label>
          <multiselect
            v-model="billingDetails.country"
            track-by="id"
            label="name"
            placeholder="Country"
            :show-labels="false"
            :disabled="!statusEdit"
            :options="countries"
          />
          <div
            v-for="(error, index) in errors.country_id"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="{'form-error': errors.vat_number}"
        >
          <label>
            VAT Number
          </label>
          <input
            v-model="billingDetails.vat_number"
            type="text"
            class="form-control"
            placeholder="VAT Number"
            :readonly="!statusEdit"
          >
          <div
            v-for="(error, index) in errors.vat_number"
            :key="index"
            class="form-error-message"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import {billingStore} from '@/store/__STORE_billing';

export default {
  name: 'BillingDetailsForm',
  components: {
    Multiselect,
  },
  mixins: [],
  props: {
    errors: {
      type: Object,
      default: () => {},
    },
    statusEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countries: [],
      // statusEdit: false,
    };
  },
  computed: {

    billingDetails: () => billingStore.billingDetails,

  },
  created() {
    this.fetchCountries();
    billingStore.getBillingDetails().then(() => {
      this.$emit('billing-details-loaded', this.billingDetails);
      if (this.statusEdit) {
        setTimeout(() => {
          this.$refs.fullName.focus();
        }, 500);
      }
    });
  },
  methods: {
    fetchCountries() {
      axios({url: '/countries', method: 'GET'}).then((response) => {
        this.countries = response.data;
      });
    },
  },
};
</script>

export default {
  data() {
    return {
      isShowPaymentModal: false,
    };
  },
  methods: {
    closePaymentMethodModal() {
      // eslint-disable-next-line
      window.$('#paymentModal').modal('hide');
      window.setTimeout(() => {
        this.isShowPaymentModal = false;
      }, 800);
    },
    showPaymentMethodModal() {
      this.isShowPaymentModal = true;
      window.setTimeout(() => {
        // eslint-disable-next-line
        window.$('#paymentModal').modal('show')
      }, 200);
    },
  },
};

<template>
  <div
    id="summaryWithTaxesModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div
        v-if="preview"
        v-click-outside="{exclude: [], handler: 'closeModal'}"
        class="modal-content"
      >
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Summary
          </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="position-relative mt-0">
            <div
              v-if="showSeparateRecurringAndTodaysChargeTables"
              class="position-absolute px-1 asLegend"
            >
              <b>Recurring charges every month</b>
            </div>

            <div
              class="table-responsive text-right pt-3 pb-2"
              :style="showSeparateRecurringAndTodaysChargeTables ? chargesTableStyle : {}"
            >
              <recurring-charge-table :preview="preview" />
            </div>
          </div>

          <div
            v-if="showSeparateRecurringAndTodaysChargeTables"
            class="position-relative mt-2"
          >
            <div
              class="position-absolute px-1 asLegend"
            >
              <b>
                Today's charge
                <span v-if="preview.billing_cycle_end">
                  - for time left until {{ preview.billing_cycle_end }}
                </span>
              </b>
            </div>
            <div
              class="table-responsive text-right pt-3 pb-2"
              :style="chargesTableStyle"
            >
              <immediate-charge-table :preview="preview" />
            </div>
          </div>

          <div class="mt-1">
            <div
              class="form-group"
              :class="{ 'form-error': hasTermsError }"
            >
              <div
                class="custom-checkbox terms payment-checkbox"
              >
                <input
                  id="terms-checkbox"
                  v-model="terms"
                  type="checkbox"
                >
                <label
                  class="custom-checkbox-right"
                  for="terms-checkbox"
                />
                I agree to the
                <a
                  href="https://simplyvideo.io/terms-of-use"
                  target="_blank"
                >
                  terms and conditions
                </a>
              </div>
              <div
                v-if="hasTermsError"
                class="form-error-message"
              >
                You must accept terms and conditions
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="goBack"
          >
            Go back
          </button>
          <button
            id="confirm-and-pay-button"
            class="btn btn-primary"
            @click="confirm"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormatMoneyMixin from '@/mixins/FormatMoneyMixin';
import RecurringChargeTable from '@/components/PaymentModals/RecurringChargeTable.vue';
import ImmediateChargeTable from '@/components/PaymentModals/ImmediateChargeTable.vue';

export default {
  components: {
    RecurringChargeTable,
    ImmediateChargeTable,
  },
  mixins: [FormatMoneyMixin],
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    preview: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      terms: false,
      hasTermsError: false,
    };
  },
  computed: {
    showSeparateRecurringAndTodaysChargeTables() {
      // this logic might change, for now lets always show separated recurring/immediate charges
      return true;
      // return this.preview.billing_cycle_end ||
      // (this.preview.recurring_charge.amount_with_tax !== this.preview.immediate_charge.amount_with_tax);
    },
    chargesTableStyle() {
      return {
        'border': '1px solid #babfc3',
        'border-radius': '5px',
      };
    },
  },
  created() {
    this.showModal();
  },
  methods: {
    showModal() {
      window.setTimeout(() => {
        // eslint-disable-next-line
        $('#summaryWithTaxesModal').modal('show');
      }, 200);
    },
    confirm() {
      if (!this.terms) {
        this.hasTermsError = true;
        return;
      }

      this.$emit('confirm-taxes');
    },
    closeModal() {
      this.$emit('close-taxes');
    },
    goBack() {
      this.$emit('go-back');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/__variables";

  .modal-content {
    margin-top: 100px;
    max-height: calc(90vh - 100px);
    overflow: auto;
    .modal-header {
      padding: 1.7rem;
      h5 {
        font-size: 2.6rem;
      }
    }
    .modal-body {
      padding: 1.7rem;
    }
  }

  .asLegend {
    background-color: $white;
    left: 10px;
    top: -10px;
  }
</style>

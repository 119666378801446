<template>
  <div
    id="paymentModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div
        v-click-outside="{exclude: [], handler: 'closeModal'}"
        class="modal-content"
      >
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Payment method
          </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closePaymentModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label>Card Holder Name</label>
          <input
            id="card-holder-name"
            v-model="name"
            type="text"
            class="form-control mb-2"
          >

          <label>Card</label>
          <div id="card-element" />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="goBack"
          >
            {{ goBackButtonText }}
          </button>
          <button
            id="add-card-button"
            class="btn btn-primary"
            @click="submitPaymentMethod"
          >
            {{ buttonText ? buttonText : 'Save Payment Method' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {billingStore} from '@/store/__STORE_billing';
import {mainStore} from '@/store/__STORE_main';

export default {
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    goBackButtonText: {
      type: String,
      default: 'Go back',
    },
  },
  data() {
    return {
      name: '',

      stripe: '',
      elements: '',
      card: '',
    };
  },
  computed: {
    stripeData: () => billingStore.stripe,
  },
  created() {
    billingStore.getStripeData().then(() => {
      this.includeStripe('js.stripe.com/v3/', function() {
        this.configureStripe();
      }.bind(this));
    });
  },
  methods: {
    closePaymentModal() {
      this.card.clear();
      this.name = '';
      this.$emit('close-payment-modal');
    },
    goBack() {
      this.card.clear();
      this.name = '';
      this.$emit('go-back');
    },
    includeStripe(URL, callback) {
      // eslint-disable-next-line
      if (typeof Stripe !== 'undefined') {
        callback();
        return;
      }

      const documentTag = document;
      const tag = 'script';
      const object = documentTag.createElement(tag);
      const scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) {
        object.addEventListener('load', function(e) {
          callback(null, e);
        }, false);
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      // eslint-disable-next-line
      this.stripe = Stripe(this.stripeData.stripe_key);

      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {hidePostalCode: true});
      this.card.mount('#card-element');
    },
    submitPaymentMethod() {
      mainStore.setLoading();
      this.stripe.confirmCardSetup(
          this.stripeData.stripe_secret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.name,
              },
            },
          },
      ).then(function(result) {
        mainStore.setSuccess();

        if (result.error) {
          this.$toast.error(result.error.message);
        } else {
          this.savePaymentMethods(result.setupIntent.payment_method);
        }
      }.bind(this));
    },
    savePaymentMethods(paymentMethod) {
      const vm = this;
      billingStore.savePaymentMethods({payment_method: paymentMethod}).then(() => {
        billingStore.getPaymentMethods().then(() => {
          mainStore.setSuccess();
          this.$emit('payment-method-saved');
          vm.closePaymentModal();
        });
      }).catch((err) => {
        try {
          const data = err.response.data;
          vm.$toast.error(data.message);
        } catch {
          return;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .modal-content {
    margin-top: 100px;
    max-height: calc(90vh - 100px);
    overflow: auto;
    .modal-header {
    padding: 1.7rem;
      h5 {
        font-size: 2.6rem;
      }
    }
    .modal-body {
      padding: 1.7rem;
      input#card-holder-name.form-control {
        font-size: 1.8rem;
        line-height: 3rem;
        padding: 0.6375rem 1.275rem;

      }
    }
  }
</style>

<template>
  <table class="my-0 table table-sm w-100">
    <tr>
      <th class="border-top-0" />
      <th class="border-top-0">
        Price
      </th>
      <th class="border-top-0">
        Quantity
      </th>
      <th class="border-top-0 pr-2">
        Subtotal
      </th>
    </tr>
    <tr
      v-for="item in preview.recurring_charge.items"
      :key="item.name"
    >
      <td>{{ item.name }}</td>
      <td>
        {{ formatMoney(item.price) }}
      </td>
      <td>{{ item.quantity }}</td>
      <td class="pr-2">
        {{ formatMoney(item.total) }}
      </td>
    </tr>
    <tr v-if="!preview.recurring_charge.items.length">
      <td
        colspan="4"
        class="text-center"
      >
        <i>
          No charges
        </i>
      </td>
    </tr>
    <tr>
      <td
        colspan="4"
        class="p-0"
      >
        <div class="table-responsive text-right">
          <table
            class="table table-borderless table-sm float-right mb-0 totals"
          >
            <tr>
              <td>Subtotal:</td>
              <td
                class="pr-2 sub-total"
              >
                {{ formatMoney(preview.recurring_charge.amount_without_tax) }}
              </td>
            </tr>
            <tr v-if="withTax">
              <td>
                VAT {{
                  preview.recurring_charge.tax_rate ?
                    preview.recurring_charge.tax_rate + '%' :
                    '(not applicable)'
                }}:
              </td>
              <td class="pr-2">
                {{ formatMoney(preview.recurring_charge.tax_amount) }}
              </td>
            </tr>
            <tr v-if="withTax">
              <td>Grand total:</td>
              <td class="pr-2">
                {{ formatMoney(preview.recurring_charge.amount_with_tax) }}
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
  </table>
</template>
<script>
import FormatMoneyMixin from '@/mixins/FormatMoneyMixin';

export default {
  mixins: [FormatMoneyMixin],
  props: {
    preview: {
      type: Object,
      default: () => {},
    },
    withTax: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .totals {
    width: 300px;
  }
  .sub-total {
    width: 55px;
  }
</style>

<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name:'user-management' }">
              Admin
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name:'user-management' }">
              User Management
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            Plans &amp; Role
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'user-management'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>Plans &amp; Role</h1>
          </div>
        </div>
        <div
          v-if="curUser.id"
          class="row row-has-panel"
        >
          <div class="col-12">
            <p class="m-0">
              Editing {{ curUser.name }} {{ curUser.last_name }} ({{ curUser.email }})
            </p>
          </div>
        </div>
        <div class="row row-has-panel">
          <div class="col-xl-6">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Plan</h3>
                </div>
              </div>
              <p
                v-if="isSVAdmin && id === null"
                class="warning-text"
              >
                {{ svAdminText }}
              </p>
              <p
                v-else-if="isUserPaymentOwner"
                class="warning-text"
              >
                {{ paymentOwnerText }}
              </p>
              <p
                v-else-if="isUserAdminOnAdminPlan"
                class="warning-text"
              >
                {{ adminUserText }}
              </p>
              <div class="section-panel-body">
                <div class="plan-subtitle">
                  <h4>Standard Plan</h4>
                </div>
                <div
                  v-for="plan in visiblePlanList"
                  :key="plan.id"
                  class="plan-option"
                  :class="{'is-checked': curPlanId === plan.id }"
                >
                  <div class="plan-option-header">
                    <span
                      :ref="`span-plan-${plan.id}`"
                      data-trigger="expandable"
                      @click="slideToggle(`plan-${plan.id}`)"
                    >
                      {{ plan.name }}
                      -
                      <span v-if="(plan.name === 'Business') && authUser.spare_business_licenses">
                        {{ formatMoney(0) }} (spare licence available)
                      </span>
                      <span v-else-if="(plan.name === 'Pro') && authUser.spare_pro_licenses">
                        {{ formatMoney(0) }} (spare licence available)
                      </span>
                      <span v-else>
                        {{ formatMoney(plan.price) }}
                      </span>
                    </span>
                    <div class="plan-option-checkbox">
                      <input
                        :id="`check-plan-${plan.id}`"
                        type="checkbox"
                        :checked="curPlanId === plan.id"
                        @change="setCurrentPlan(plan.id, $event)"
                      >
                      <label :for="`check-plan-${plan.id}`" />
                    </div>
                  </div>
                  <div
                    :ref="`div-plan-${plan.id}`"
                    class="expandable"
                  >
                    <div class="expandable-content">
                      <p>
                        {{ plan.description }}
                      </p>
                      <p
                        v-if="plan.name === 'Business' || plan.name === 'Pro'"
                        class="warning"
                      >
                        Minimum purchase is 2 licences
                        <br>
                        After the purchase you can assign licences to individual users
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  v-if="currentPlan.included_addons && currentPlan.included_addons.length"
                  class="plan-subtitle"
                >
                  <h4>Included Addons</h4>
                </div>
                <div
                  v-for="addon in currentPlan.included_addons"
                  :key="`inc-${addon.id}`"
                  class="plan-option is-checked"
                >
                  <div class="plan-option-header">
                    <span
                      :ref="`span-inc-${addon.id}`"
                      data-trigger="expandable"
                      @click="slideToggle(`inc-${addon.id}`)"
                    >
                      {{ addon.name }}
                      <!--
                      - {{ formatMoney(addon.price) }}
                      -->
                    </span>
                    <div class="plan-option-checkbox">
                      <input
                        :id="`check-inc-addon-${addon.id}`"
                        type="checkbox"
                        checked
                        disabled
                      >
                      <label :for="`check-inc-addon-${addon.id}`" />
                    </div>
                  </div>
                  <div
                    :ref="`div-inc-${addon.id}`"
                    class="expandable"
                  >
                    <div class="expandable-content">
                      <p>
                        {{ addon.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="currentPlan.available_addons && currentPlan.available_addons.length"
                  class="plan-subtitle"
                >
                  <h4>Available Addons</h4>
                </div>
                <div
                  v-for="addon in currentPlan.available_addons"
                  :key="`avl-${addon.id}`"
                  class="plan-option"
                  :class="{'is-checked': isIncludeAddon(addon.id)}"
                >
                  <div class="plan-option-header">
                    <span
                      :ref="`span-avl-${addon.id}`"
                      data-trigger="expandable"
                      @click="slideToggle(`avl-${addon.id}`)"
                    >
                      {{ addon.name }} - {{ formatMoney(addon.price) }}
                    </span>
                    <div class="plan-option-checkbox">
                      <input
                        :id="`check-avl-addon-${addon.id}`"
                        type="checkbox"
                        :checked="isIncludeAddon(addon.id)"
                        @change="changeAddon(addon.id, $event)"
                      >
                      <label :for="`check-avl-addon-${addon.id}`" />
                    </div>
                  </div>
                  <div
                    :ref="`div-avl-${addon.id}`"
                    class="expandable"
                  >
                    <div class="expandable-content">
                      <p>
                        {{ addon.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="curUser.id"
            class="col-xl col-sm-6 mt-2 mt-xl-0"
          >
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Role</h3>
                </div>
              </div>
              <div class="section-panel-body">
                <div
                  v-for="role in availableRoleList"
                  :key="`role-${role.id}`"
                  v-tooltip.left="{content: toolTipText(role.id)}"
                  class="custom-checkbox"
                >
                  <div v-if="role.role_name === 'admin' && !accountHasAdminManagement">
                    <input
                      :id="`check-role-${role.id}`"
                      type="checkbox"
                      :checked="role.id === curRoleId"
                      @click.prevent="onAdminRoleClicked"
                    >
                    <label :for="`check-role-${role.id}`">{{ role.display_name }}</label>
                  </div>
                  <div v-else-if="availableRoleList.length === 1">
                    <input
                      :id="`check-role-${role.id}`"
                      type="checkbox"
                      :checked="role.id === curRoleId"
                      @click.prevent=""
                    >
                    <label :for="`check-role-${role.id}`">{{ role.display_name }}</label>
                  </div>
                  <div v-else>
                    <input
                      :id="`check-role-${role.id}`"
                      :disabled="currentPlanHasAdminRole(role.id)"
                      :checked="role.id === curRoleId"
                      type="checkbox"
                      @change="setCurrentRole(role.id, $event)"
                    >
                    <label :for="`check-role-${role.id}`">{{ role.display_name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="curUser.id && isShowGotcha"
            class="col-xl col-sm-6 mt-2 mt-xl-0"
          >
            <gotcha
              :tip-list="tipList"
              @close="isShowGotcha = false"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr>
            <div
              v-if="isPaymentMethodsLoaded"
              class="text-right"
            >
              <router-link
                class="btn btn-text"
                :to="{ name:'user-management' }"
              >
                Revert changes
              </router-link>

              <span v-if="currentPlan">
                <a
                  id="proceed-to-summary"
                  class="btn btn-primary text-white"
                  @click.prevent="showSummaryWithoutTaxesModal"
                >
                  Proceed to summary
                </a>
              </span>
              <span v-else>
                <a class="btn btn-outline-dark disabled">
                  Please select plan
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <summary-without-taxes-modal
      v-if="isSummaryWithoutTaxesVisible"
      :preview="preview"
      button-text="Proceed"
      @close-without-taxes="closeSummaryWithoutTaxesModal"
      @confirm-without-taxes="confirmSummaryWithoutTaxes"
    />

    <billing-details-modal
      v-if="isBillingDetailsVisible"
      @close-billing="closeBillingDetailsModal"
      @go-back="goBackFromBillingDetailsModal"
      @confirm="confirmBillingDetails"
    />

    <summary-with-taxes-modal
      v-if="isSummaryWithTaxesVisible"
      :preview="preview"
      :button-text="summaryWithTaxesButtonText"
      @close-taxes="closeSummaryWithTaxesModal"
      @go-back="goBackFromSummaryWithTaxesModal"
      @confirm-taxes="confirmSummaryWithTaxes"
    />

    <payment-method-modal
      v-if="isShowPaymentModal"
      :button-text="addPaymentMethodButtonText"
      close-button-text="Go back"
      @close-payment-modal="closePaymentMethodModal"
      @go-back="goBackFromPaymentMethodModal"
      @payment-method-saved="onPaymentMethodSaved"
    />
  </main>
</template>
<script>
import {billingStore} from '@/store/__STORE_billing';
import {userStore} from '@/store/__STORE_user';
import {mainStore} from '@/store/__STORE_main';
import {callStore} from '@/store/__STORE_call';
import Gotcha from '@/components/Gotcha.vue';
import PlanMixin from '@/mixins/PlanMixin';
import PaymentMethodModal from '@/components/PaymentModals/PaymentMethodModal.vue';
import SummaryWithTaxesModal from '@/components/PaymentModals/SummaryWithTaxesModal.vue';
import BillingDetailsModal from '@/components/PaymentModals/BillingDetailsModal.vue';
import SummaryWithoutTaxesModal from '@/components/PaymentModals/SummaryWithoutTaxesModal.vue';
import PaymentMethodModalMixin from '@/mixins/PaymentMethodModalMixin';
import FormatMoneyMixin from '@/mixins/FormatMoneyMixin';
import axios from 'axios';

export default {
  components: {
    Gotcha,
    PaymentMethodModal,
    SummaryWithTaxesModal,
    SummaryWithoutTaxesModal,
    BillingDetailsModal,
  },
  mixins: [PlanMixin, FormatMoneyMixin, PaymentMethodModalMixin],
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    prevRoute: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      curPlanId: '',
      curRoleId: '',
      curAddons: [],
      curUser: '',
      isShowGotcha: true,
      tipList: [
        'Select the standard plan you need',
        'Turn on the features you need by picking add on\'s such as Video Gateway or XR Gateway',
        'Add storage for recording',
      ],
      isPaymentMethodsLoaded: false,
      preview: {},
      isSummaryWithoutTaxesVisible: false,
      isSummaryWithTaxesVisible: false,
      isBillingDetailsVisible: false,

      // This variable is used to know which step of the checkout process to show when user want to "Go back" from
      // "Summary with taxes" step. If user had to fill billing details, then they will go back to "Billing details"
      // step. If they didn't have to fill billing details, then they will go to "Summary without taxes"
      hadToFillBillingDetails: false,
    };
  },
  computed: {
    isSVAdmin: () => userStore.__GETTERisSVAdmin,
    roleList: () => billingStore.roles,
    planList: () => billingStore.plans,
    addonList: () => billingStore.addons,
    paymentMethods: () => billingStore.paymentMethods,
    userList: () => userStore.subUsers,
    visiblePlanList() {
      return this.planList.filter((plan) => {
        if (!this.authUser.billing_plan || !this.curUser.billing_plan) {
          return false;
        }

        if (plan.name === 'Free' && this.authUser.billing_plan.name === 'Trial') {
          return false;
        }

        if (plan.name === 'Trial' && this.authUser.billing_plan.name !== 'Trial') {
          return false;
        }

        // Don't show Business plan unless this user already has it or there are spare business licenses in the acc
        const isBusinessPlanAllowed = (this.curUser.billing_plan.name === 'Business' ||
              this.authUser.spare_business_licenses);

        if (plan.name === 'Business' && !isBusinessPlanAllowed) {
          return false;
        }

        // Payment owner can only be on pro if there are pro users
        if (
          (this.curUser.account.is_payment_owner && this.curUser.account.has_admin_management) &&
              (plan.name !== 'Business' && plan.name !== 'Pro')
        ) {
          return false;
        }

        // Don't allow admin users to downgrade their plan as it would downgrade their role
        if (
          (this.curUser.id === this.authUser.id && this.curUser.role === 'Admin' && this.curUser.account.has_admin_management && this.userList.length > 1) &&
               (plan.name !== 'Business' && plan.name !== 'Pro')
        ) {
          return false;
        }

        return true;
      });
    },
    defaultPaymentMethod() {
      return this.paymentMethods.find((element) => element.is_default);
    },
    currentPlan() {
      return this.curPlanId ? this.planList.find((item) => item.id === this.curPlanId) : '';
    },
    summaryWithTaxesButtonText() {
      if (!this.defaultPaymentMethod && this.curPlanId !== 1) {
        return 'Proceed to payment method';
      }

      return 'Confirm and pay ' + this.formatMoney(this.preview.immediate_charge.pay_now);
    },
    addPaymentMethodButtonText() {
      return 'Confirm and pay ' + this.formatMoney(this.preview.immediate_charge.pay_now);
    },
    availableRoleList() {
      // If downgrading show all options
      if (!this.currentPlan.has_admin_management) {
        return this.roleList;
      }

      // Regular user cannot remove Admin role privileges
      if (this.curUser.role === 'Admin' && this.authUser.role !== 'Admin') {
        return [
          {id: 1, display_name: 'Admin', role_name: 'admin'},
        ];
      }

      // Admin cannot remove admin privileges from himself
      if (this.authUser.role === 'Admin' && (this.authUser.id === this.curUser.id) && this.userList.length > 1) {
        return [
          {id: 1, display_name: 'Admin', role_name: 'admin'},
        ];
      }

      // Payment owner cannot have admin removed from them
      if (this.curUser.account.is_payment_owner && this.curUser.account.has_admin_management) {
        return [
          {id: 1, display_name: 'Admin', role_name: 'admin'},
        ];
      }

      return this.roleList;
    },
    isUserPaymentOwner() {
      return this.curUser.account && this.curUser.account.is_payment_owner && this.curUser.account.has_admin_management;
    },
    isUserAdminOnAdminPlan() {
      if (this.curUser.id === this.authUser.id && this.userList.length > 1) {
        return this.curUser.account && this.curUser.account.has_admin_management && this.curUser.role === 'Admin';
      }
      return false;
    },
    paymentOwnerText() {
      if (this.curUser.id === this.authUser.id) {
        return 'You cannot change your role or plan while you are the paying user but you can amend your addons. To reduce your plan first reduce everyone else\'s and remove your payment details.';
      } else {
        return 'The paying admin\'s role and plan cannot be changed, but you can change their addons. If you wish to edit their plan or role you must remove their payment details and add your own.';
      }
    },
    adminUserText() {
      return 'You cannot downgrade your plan as you would lose your admin access rights. If you wish to be downgraded get another admin to do this for you.';
    },
    svAdminText() {
      return 'As an SV admin you cannot change your plan in any way. If you have any issues please contact the dev team.';
    },
  },
  watch: {
    curPlanId(val) {
      const selectedPlan = this.planList.find((item) => item.id === val);
      if (selectedPlan && !selectedPlan.has_admin_management && this.curRoleId === 1) {
        this.$toast.error('This plan does not support admin role.');
        this.curRoleId = 2;
      }
      setTimeout(() => {
        const vm = this;
        vm.planList.forEach((element) => {
          if (element.id === val) {
            vm.slideExpand(`plan-${element.id}`, true);
            if (element.included_addons && element.included_addons.length) {
              element.included_addons.forEach((subElement) => {
                vm.slideExpand(`inc-${subElement.id}`, true);
              });
            }
          } else {
            vm.slideExpand(`plan-${element.id}`, false);
          }
        });
      }, 200);
    },
  },
  created() {
    if (this.id) {
      this.getSubUser();
    } else {
      this.getUserProfile();
    }

    billingStore.getPlanData();
    billingStore.getAddonData();

    billingStore.getPaymentMethods().then(() => {
      this.isPaymentMethodsLoaded = true;
    });
  },
  methods: {
    toolTipText(id) {
      if (id === 1) {
        const plan = this.currentPlan;
        if (!plan.has_admin_management) {
          return 'You cannot select this role on this plan.';
        }
        return '';
      }
      return '';
    },
    currentPlanHasAdminRole(id) {
      if (id === 1) {
        const selectedPlan = this.planList.find((item) => item.id === this.curPlanId);
        return !selectedPlan.has_admin_management;
      }
      return false;
    },
    confirmSummaryWithoutTaxes() {
      this.closeSummaryWithoutTaxesModal();
      if (this.preview.billing_details_filled && !this.hadToFillBillingDetails) {
        this.showSummaryWithTaxesModal();
      } else {
        this.showBillingDetailsModal();
      }
    },
    confirmBillingDetails() {
      this.hadToFillBillingDetails = true;
      this.closeBillingDetailsModal();
      this.showSummaryWithTaxesModal();
    },
    confirmSummaryWithTaxes() {
      this.closeSummaryWithTaxesModal();

      if (this.defaultPaymentMethod || this.curPlanId === 1) {
        this.updatePlanRole();
        return;
      }

      this.showPaymentMethodModal();
    },
    onPaymentMethodSaved() {
      this.updatePlanRole();
    },
    onAdminRoleClicked() {
      if (this.curUser.role === 'Admin') {
        return;
      }

      const mainText = 'Only Pro plan users can use account admins. Upgrade your account to unlock this feature.';
      this.$emit('show-premium-feature-popup', {
        mainText: mainText,
      });
    },
    getSubUser() {
      userStore.getSubUser(this.id).then((response) => {
        this.curUser = response;
        this.initPlanRole();
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
    getUserProfile() {
      userStore.getUserProfile().then((response) => {
        this.curUser = response.data;
        this.initPlanRole();
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
    setCurrentPlan(id, event) {
      if (event.target.checked) {
        const vm = this;
        vm.curPlanId = id;
        vm.curAddons = [];
        const curPlan = vm.planList.find((item) => item.id === id);
        curPlan.included_addons.forEach((item) => {
          vm.curAddons.push(item);
        });
      } else {
        this.curPlanId = '';
        this.curAddons = [];
      }
    },
    setCurrentRole(id, event) {
      if (event.target.checked) {
        this.curRoleId = id;
      } else {
        this.curRoleId = '';
      }
    },
    slideToggle(id) {
      if (!this.$refs['div-' + id] || !this.$refs['span-' + id]) {
        return;
      }

      const expandWidget = this.$refs[`div-${id}`][0];
      const expandSpan = this.$refs[`span-${id}`][0];
      if (expandSpan.classList.value) {
        expandSpan.classList = [];
        expandWidget.style.maxHeight = '';
      } else {
        expandSpan.classList = ['is-active'];
        expandWidget.style.maxHeight = expandWidget.scrollHeight + 'px';
      }
    },
    slideExpand(id, status) {
      if (!this.$refs['div-' + id] || !this.$refs['span-' + id]) {
        return;
      }

      const expandWidget = this.$refs[`div-${id}`][0];
      const expandSpan = this.$refs[`span-${id}`][0];
      if (status) {
        expandSpan.classList = ['is-active'];
        expandWidget.style.maxHeight = expandWidget.scrollHeight + 'px';
      } else {
        expandSpan.classList = [];
        expandWidget.style.maxHeight = '';
      }
    },
    isIncludeAddon(id) {
      const findIndex = this.curAddons.findIndex((item) => item.id === id);
      if (findIndex >= 0) {
        this.slideExpand(`avl-${id}`, true);
        return true;
      } else {
        return false;
      }
    },
    changeAddon(id, event) {
      if (event.target.checked) {
        const curAddon = this.addonList.find((item) => item.id === id);
        this.curAddons.push(curAddon);

        if (curAddon.name === '24h Storage for Recordings') {
          this.deselect100GbStorageAddon();
          this.deselect500GbStorageAddon();
        }

        if (curAddon.name === '100GB Storage for Recordings') {
          this.deselect500GbStorageAddon();
          this.deselect24hStorageAddon();
        }

        if (curAddon.name === '500GB Storage for Recordings') {
          this.deselect100GbStorageAddon();
          this.deselect24hStorageAddon();
        }
        this.slideExpand(`avl-${id}`, true);
      } else {
        const delIndex = this.curAddons.findIndex((item) => item.id === id);
        this.curAddons.splice(delIndex, 1);
        this.slideExpand(`avl-${id}`, false);
      }
    },
    deselect100GbStorageAddon() {
      const indexOf100 = this.curAddons.findIndex((curAddon) => {
        return curAddon.name === '100GB Storage for Recordings';
      });

      if (indexOf100 >= 0) {
        this.curAddons.splice(indexOf100, 1);
      }
    },
    deselect500GbStorageAddon() {
      const indexOf500 = this.curAddons.findIndex((curAddon) => {
        return curAddon.name === '500GB Storage for Recordings';
      });

      if (indexOf500 >= 0) {
        this.curAddons.splice(indexOf500, 1);
      }
    },
    deselect24hStorageAddon() {
      const indexOf24 = this.curAddons.findIndex((curAddon) => {
        return curAddon.name === '24h Storage for Recordings';
      });

      if (indexOf24 >= 0) {
        this.curAddons.splice(indexOf24, 1);
      }
    },
    initPlanRole() {
      if (this.curUser) {
        this.curPlanId = this.curUser.billing_plan ? this.curUser.billing_plan.id : '';
        if (this.curUser.role) {
          const curRole = this.roleList.find((role) => role.display_name === this.curUser.role);
          this.curRoleId = curRole ? curRole.id : '';
        } else {
          this.curRoleId = '';
        }
        this.curAddons = [...this.curUser.addons];
      } else {
        this.curPlanId = '';
        this.curRoleId = '';
        this.curAddons = [];
      }
    },
    loadPreview(successCallback) {
      mainStore.setLoading();

      const includedAddonIds = this.currentPlan.included_addons.map((includedAddon) => {
        return includedAddon.id;
      });

      const selectedAddonIds = this.curAddons.map((curAddon) => {
        return curAddon.id;
      }).filter((addonId) => {
        return includedAddonIds.indexOf(addonId) === -1;
      });

      const payload = {
        billing_plan_id: this.currentPlan.id,
        addon_ids: selectedAddonIds,
      };

      axios.patch('/preview-subusers-update/' + this.curUser.id, payload).then((response) => {
        // this.isPreviewLoading = false;
        this.preview = response.data;
        mainStore.setStatus('');

        if (successCallback) {
          successCallback();
        }
        // this.showModal();
      }).catch((error) => {
        mainStore.setStatus('');
        this.$toast.error('Error.');
      });
    },
    closeSummaryWithoutTaxesModal() {
      // eslint-disable-next-line
      $('#summaryWithoutTaxesModal').modal('hide');
      window.setTimeout(() => {
        this.isSummaryWithoutTaxesVisible = false;
      }, 800);
    },
    showSummaryWithoutTaxesModal(reloadPreview = true) {
      if (this.isSVAdmin && this.id === null) {
        this.$toast.error('You are not able to change any thing related to your plan.');
        return;
      }
      if (reloadPreview) {
        this.loadPreview(() => {
          this.isSummaryWithoutTaxesVisible = true;
        });
        return;
      }

      this.isSummaryWithoutTaxesVisible = true;
    },
    closeSummaryWithTaxesModal() {
      // eslint-disable-next-line
      $('#summaryWithTaxesModal').modal('hide');
      window.setTimeout(() => {
        this.isSummaryWithTaxesVisible = false;
      }, 800);
    },
    showBillingDetailsModal() {
      this.loadPreview(() => {
        this.isBillingDetailsVisible = true;
      });
    },
    closeBillingDetailsModal() {
      // eslint-disable-next-line
      $('#billingDetailsModal').modal('hide');
      window.setTimeout(() => {
        this.isBillingDetailsVisible = false;
      }, 800);
    },
    goBackFromBillingDetailsModal() {
      this.closeBillingDetailsModal();
      this.showSummaryWithoutTaxesModal();
    },
    showSummaryWithTaxesModal(reloadPreview = true) {
      if (reloadPreview) {
        this.loadPreview(() => {
          this.isSummaryWithTaxesVisible = true;
        });
        return;
      }

      this.isSummaryWithTaxesVisible = true;
    },
    goBackFromSummaryWithTaxesModal() {
      this.closeSummaryWithTaxesModal();
      if (this.hadToFillBillingDetails) {
        this.showBillingDetailsModal();
      } else {
        setTimeout(() => {
          this.showSummaryWithoutTaxesModal(false);
        }, 1);
      }
    },
    goBackFromPaymentMethodModal() {
      this.closePaymentMethodModal();
      this.showSummaryWithTaxesModal();
    },
    updatePlanRole() {
      const vm = this;
      if (!vm.curPlanId) {
        vm.$toast.error('Please select a plan!');
        return;
      }
      if (!vm.curRoleId) {
        vm.$toast.error('Please select a role!');
        return;
      }
      const curRoleName = vm.roleList.find((role) => role.id === vm.curRoleId).role_name;

      const includedAddonIds = this.currentPlan.included_addons.map((includedAddon) => {
        return includedAddon.id;
      });

      const addonIds = vm.curAddons.map((curAddon) => {
        return curAddon.id;
      }).filter((addonId) => {
        return includedAddonIds.indexOf(addonId) === -1;
      });

      const params = {
        role: curRoleName,
        billing_plan_id: vm.curPlanId,
        addon_ids: addonIds,
        terms: 1,
      };

      mainStore.setLoading();

      callStore.clearVMR(); // So the cached version doesn't hold addons possibly removed

      axios({url: '/subusers/' + vm.curUser.id, data: params, method: 'PATCH'}).then((response) => {
        const paymentIntentClientSecret = response.data.payment_intent_client_secret;

        if (response.status === 200 && paymentIntentClientSecret) {
          this.confirmCardPayment(paymentIntentClientSecret);
        } else {
          this.onUpdated();
        }
      }).catch((err) => {
        if (err.response && err.response.data) {
          const data = err.response.data;
          vm.$toast.error(data.message);
          if (err.response.status === 422) {
            for (const messages of Object.values(data.errors)) {
              for (const msg of messages) {
                vm.$toast.error(msg);
              }
            }
          }
        } else {
          vm.$toast.error(err);
        }
      });
    },
    includeStripe(URL, callback) {
      // eslint-disable-next-line
      if (typeof Stripe !== 'undefined') {
        callback();
        return;
      }

      const documentTag = document;
      const tag = 'script';
      const object = documentTag.createElement(tag);
      const scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) {
        object.addEventListener('load', function(e) {
          callback(null, e);
        }, false);
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    confirmCardPayment(paymentIntentClientSecret) {
      axios({url: '/payments/form', method: 'GET'}).then((resp) => {
        this.includeStripe('js.stripe.com/v3/', () => {
          // eslint-disable-next-line
          let stripe = Stripe(resp.data.stripe_key);

          stripe.confirmCardPayment(paymentIntentClientSecret).then((result) => {
            if (result.error) {
              const paymentIntentId = result.error.payment_intent.id;
              this.onPaymentConfirmationFailed(result.error, paymentIntentId);
            }

            if (result.paymentIntent) {
              axios({url: '/confirm-payment/' + result.paymentIntent.id, data: {}, method: 'POST'}).then(() => {
                mainStore.setStatus('');
                this.onUpdated();
              }).catch((error) => {
                this.onPaymentConfirmationFailed(error);
              });
            }
          });
        });
      }).catch((error) => {
        this.onPaymentConfirmationFailed(error);
      });
    },
    onUpdated() {
      userStore.getSubUsers().then(() => {
        this.$router.push({name: 'user-management'});
        this.$toast.success('Plan and addons updated successfully!');
      });
    },
    onPaymentConfirmationFailed(error, paymentIntentId) {
      if (paymentIntentId) {
        axios({url: '/failed-payment/' + paymentIntentId, data: {}, method: 'POST'}).then(() => {
          mainStore.setStatus('');
          this.$toast.error('Payment confirmation failed');
        }).catch((error) => {
          mainStore.setStatus('');
          this.$toast.error('Payment confirmation failed');
        });

        return;
      }

      mainStore.setStatus('');
      this.$toast.error('Payment confirmation failed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";
  .warning {
    color: $darkred;
  }
  .warning-text {
    margin: unset;
    padding: 15px 25px 0 25px;
  }
</style>

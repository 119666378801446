<template>
  <div
    id="billingDetailsModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      v-click-outside="{exclude: [], handler: 'closeModal'}"
      class="modal-dialog"
      role="document"
    >
      <div
        class="modal-content"
      >
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Billing details
          </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <billing-details-form :errors="errors" />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="goBack"
          >
            Go back
          </button>
          <button
            id="add-card-button"
            class="btn btn-primary"
            @click="saveBillingDetails"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import BillingDetailsForm from '@/components/PaymentModals/BillingDetailsForm.vue';
import {billingStore} from '@/store/__STORE_billing';
export default {
  components: {
    BillingDetailsForm,
  },
  mixins: [],
  props: {},
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    billingDetails: () => billingStore.billingDetails,
  },
  created() {
    this.showModal();
  },
  methods: {
    showModal() {
      window.setTimeout(() => {
        // eslint-disable-next-line
        $('#billingDetailsModal').modal('show');
      }, 200);
    },
    saveBillingDetails() {
      billingStore.updateBillingDetails(this.billingDetails).then(() => {
        this.errors = {};
        this.$emit('confirm');
      }).catch((err) => {
        const data = err.response.data;
        if (err.response.status === 422) {
          this.errors = data.errors;
          return;
        }
        this.$toast.error(data.message);
      });
    },
    closeModal() {
      this.$emit('close-billing');
    },
    goBack() {
      this.$emit('go-back');
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-content {
    margin-top: 100px;
    max-height: calc(90vh - 100px);
    overflow: auto;
  }
</style>
